<template>
  <div class="content">
    <div class="container vertical-center text-center">
      <div class="card">
        <div class="first text-start p-4">
          <div class="row">
            <div class="col-sm-6 col-md-6 col-lg-6">
              <div class="profile-image">
                <img src="img/profile_image.png" alt="profile image" height="272px" width="auto">
              </div>
            </div>
            <div class="col-sm-6 col-md-6 col-lg-6">
              <div class="title-block text-center">
                <h1>Moritz Witte</h1>
                <div class="social-links">
                  <a href="https://twitter.com/DevMoritz" target="_blank">
                    <i class="fa fa-twitter"></i>
                  </a>
                  <a href="https://github.com/DevMoritz" target="_blank">
                    <i class="fa fa-github"></i>
                  </a>
                  <a href="https://www.instagram.com/DevMoritz" target="_blank">
                    <i class="fa fa-instagram"></i>
                  </a>
                </div>
              </div>
              <div class="text-center">
                <router-link to="de" class="flag">
                  <img src="img/flags/germany.svg" alt="Germany">
                </router-link>
                <router-link to="en" class="flag">
                  <img src="img/flags/usa.svg" alt="English">
                </router-link>
              </div>
            </div>
          </div>
        </div>
        <div class="second text-start p-4">
          <div class="pt-4">
            <div class="row">
              <div class="col-sm-6 col-md-6 col-lg-6">
                <h4 v-html="this.getCurrentLanguageData().about.title"></h4>
                <p>{{ this.getCurrentLanguageData().about.text }}</p>
              </div>
              <div class="col-sm-6 col-md-6 col-lg-6">
                <ul class="information-list">
                  <li>
                    <span class="title">{{ this.getCurrentLanguageData().date.title }}</span>
                    <span class="value">{{ calcBirth('2002-02-20') }}</span>
                  </li>
                  <li v-for="(item, index) in this.getCurrentLanguageData().list" :key="index">
                    <span class="title">{{ item.title }}</span>
                    <span class="value">{{ item.value }}</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="third text-start pb-4">
          <div class="row">
            <div class="col-6">
              <router-link to="legal" class="float-end">Legal Notice</router-link>
            </div>
            <div class="col-6">
              <router-link to="privacy">Privacy</router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Home',
  metaInfo: {
    title: 'Homepage'
  },
  data: () => ({
    pageData: {}
  }),
  created() {
    console.log('created')
    this.pageData = this.getCurrentLanguageData();
  },
  methods: {
    getLang() {
      return this.$attrs.language;
    },
    getCurrentLanguageData() {
      const languages = ['de', 'en'];
      const curLang = this.getLang();
      const langData = this.getLanguageData();

      if (languages.includes(curLang)) {
        return langData[curLang];
      }
      return langData['de'];
    },
    calcBirth(date) {
      const today = new Date();
      const birthDate = new Date(date);
      let age = today.getFullYear() - birthDate.getFullYear();
      const m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      return age;
    },
    getLanguageData() {
      return {
        de: {
          list: [
            {
              title: 'Land',
              value: 'Deutschland'
            },
            {
              title: 'Adresse',
              value: 'Riehestr. 34a, 31535 Eilvese'
            },
            {
              title: 'E-Mail',
              value: 'admin@devmoritz.de'
            },
            {
              title: 'Tel.',
              value: '+49 172 5825268'
            },
          ],
          about: {
            title: 'Über <span style="color: #BB86FC">Mich</span>',
            text: "Hey, ich bin Moritz, 19 Jahre alt. Aktuell mache eine Ausbildung zum Fachinformatiker für Anwendungsentwicklung. Die Ausbildung schließe ich voraussichtlich im Sommer 2021 ab."
          },
          date: {
            title: 'Alter'
          },
        },
        en: {
          list: [
            {
              title: 'Country',
              value: 'Germany'
            },
            {
              title: 'Adress',
              value: 'Riehestr. 34a, 31535 Eilvese'
            },
            {
              title: 'Mail',
              value: 'admin@devmoritz.de'
            },
            {
              title: 'Phone',
              value: '+49 172 5825268'
            },
          ],
          about: {
            title: 'About <span style="color: #BB86FC">Me</span>',
            text: "Hey, I'm Moritz, 19 years old. I am currently training to become an IT specialist for application development. I will probably finish my apprenticeship in the summer of 2021."
          },
          date: {
            title: 'Age'
          },
        },
      }
    }
  }
}
</script>

<style scoped>
/** Global **/
body {
  font-family: 'Roboto', sans-serif !important;
  background-color: #121212;
  color: #121212;
  height: 100%;
}

a {
  color: #03DAC6;
  text-decoration: unset;
}

.vertical-center {
  max-width: 936px;
  position: relative;
  width: 100%;
  margin: 0 auto;
  padding-top: 100px;
}

/** Change color of upper block **/
.first {
  background-color: #BB86FC;
}

/** Information Block **/
.information-list {
  display: inline-block;
  margin: 0;
  padding: 0;
  list-style: none;
}

.information-list li {
  margin: 6px 0;
  text-align: left;
}

.information-list li .title {
  display: inline-block;
  min-width: 90px;
}

.information-list li .value {
  display: inline-block;
  color: #9e9e9e;
}

/** Profile Block **/
.profile-image {
  width: 278px;
  margin-bottom: -50px;
  transition: all .3s ease-in-out;
}

.profile-image img {
  border: 3px solid #fff;
  box-shadow: 0 0 15px 0 rgb(84 88 90);
}

.title-block h1 {
  color: #fff;
  font-size: 54px;
  font-weight: 700;
  margin: 30px 0 0;
}

/** Social-Links Block **/
.social-links {
  position: relative;
  text-align: center;
  margin: 20px auto 0;
}

.social-links a {
  display: inline-block;
  width: 36px;
  height: 36px;
  border-radius: 24px;
  background-color: #fff;
  margin: 0 2px;
  color: #9e9e9e;
  text-align: center;
  box-shadow: 0 0 15px 0 rgb(84 88 90);
}

.social-links a i {
  line-height: 36px;
  font-size: 16px;
}

.flag {
  margin: 0 2px;
  width: 36px;
  height: 36px;
  object-fit: cover;
  border-radius: 24px;
}

.flag img {
  object-fit: cover;
  width: 36px;
  height: 36px;
  border-radius: 24px;
  box-shadow: 0 0 15px 0 rgb(84 88 90);
}

/** Small devices */
@media (max-width: 767px) {
  .profile-image {
    width: 100%;
    margin-bottom: 0 !important;
    text-align: center;
    height: 246px;
  }

  .profile-image img {
    height: 240px;
  }
}
</style>
